import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogDynamic from "../pannel/dialog";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  DialogContent,
  Typography,
} from "@mui/material";
import CheckboxesPrivilege from "../pannel/checkBoxesPrivilege";
import {toast} from 'react-toastify'

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import api from "../../API/api";
import useMUIModalState from "../../hooks/useMUIModalState";
import useBasicLoader from "../../hooks/useBasicLoader";

function StudyMaterialList(props) {
  const { open, handleClose, handleOpen, selectedValue, handleSelectedValue} =
    useMUIModalState();

    const {loading: isDeleting, changeLoading: setIsDeleting} = useBasicLoader(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    handleSelectedValue(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleDelete = ()=>{
    setIsDeleting(true);
    // network request to delete the study material
    api.deleteStudyMaterial(selectedValue.id).then((res)=>{
      props.removeStudyMaterialClient(selectedValue.id);
      toast.success(res.data.message, {
       autoClose: 800
      });
      handleClose();
      handleMenuClose();
    }).catch(error => {
      toast.error(error.response.data.message);
      handleClose();
    });
    setIsDeleting(false);

  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>sl</TableCell>
            <TableCell align="right">Subject</TableCell>
            <TableCell align="right">Syllabus</TableCell>
            <TableCell align="right">Topic</TableCell>
            <TableCell align="right">Data</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.stydyMaterials &&
            props.stydyMaterials.map((m, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">{m.subject.subName}</TableCell>
                <TableCell align="right">{m.syllabus.syllabus}</TableCell>
                {/* <TableCell align="right">Active</TableCell> */}
                <TableCell align="right">
                  <Link to={m.file}>View File</Link>
                </TableCell>
                <TableCell align="right">
                  <Button
                    size="small"
                    onClick={() => navigate("/admin/StudyMaterialForm")}
                    color="success"
                    variant="contained"
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleMenuOpen(e, m)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
              
                  >
                    <MenuItem
                      onClick={() => {
                        
                        handleOpen();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Deleting Study Material: subject - ${selectedValue && selectedValue.subject.subName}, syllabus - ${selectedValue && selectedValue.syllabus.syllabus}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
        
              Are you sure you want to deleted this Study Material?
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}
          disabled={isDeleting}
          >Close</Button>
          <Button onClick={handleDelete} autoFocus color="error" disabled={isDeleting}>
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
export default StudyMaterialList;
