import API from "./apiConfig";
import { FileAPI } from "./apiConfig";

export default {
  // APP USER RELATED
  getTotalPagesForAppUsers(batch_size) {
    return API.get(`/users/total-pages?batch_size=${batch_size}`);
  },

  rewardMaster(data) {
    return API.post("/rewardMaster", data);
  },

  examDataById(id) {
    return API.post("/examDataById", { id });
  },

  updateExamData(examData) {
    return API.post("/updateExamData", examData);
  },

  getExamType() {
    return API.get("/getExamType");
  },

  getExamName() {
    return API.get("/getExamName");
  },

  getSubject() {
    return API.get("/getSubject");
  },

  getSyllabus() {
    return API.get("/getSyllabus");
  },
  getSyllabusBySub(id) {
    return API.get(`/getSyllabusBySub/${id}`);
  },
  moveSyllabus(folderId, fileId) {
    return API.post("/moveSyllabus", { folderId, fileId });
  },
  copySyllabus(folderId, fileId) {
    return API.post("/copySyllabus", { folderId, fileId });
  },

  // QUESTION RELATED
  postQuestion(fd) {
    return API.post("/postQuestion", fd, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  editQuestion(data) {
    return API.post("editQuestion", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getQuestion() {
    return API.get(`/getQuestion`);
  },
  deleteQuestion(data) {
    return API.post("/deleteQuestion", { data });
  },

  postOrganization(organization, examType_id) {
    return API.post("/postOrganization", { organization, examType_id });
  },

  getOrganization() {
    return API.get("/getOrganization");
  },
  getOrganizationById(data) {
    return API.post("/getOrganizationbyId", { id: data });
  },
  createSylFile(type, id, name, selfId) {
    return API.post("/createSylFile", { type, id, name, selfId });
  },
  getSyllabusById(subId, id) {
    return API.post("/getSyllabusById", { subId, id });
  },

  deleteSyl(id) {
    return API.post("deleteSyl", { id });
  },
  getTheSyl(id) {
    return API.get(`getTheSyl/${id}`);
  },

  getQuestionBySyl(id, page, per_page) {
    return API.get(`getQuestionBySyl/${id}/${per_page}/${page}`);
  },
  getUserBaseQuestionBySyl(id, adder, page, per_page) {
    return API.get(
      `getUserBaseQuestionBySyl/${id}/${adder}/${per_page}/${page}`
    );
  },

  updateSyllabus(syl, id) {
    return API.post("updateSyllabus", { syl, id });
  },

  addQuestionTemplate(template, sections) {
    return API.post("addQuestionTemplate", { template, sections });
  },
  getTemplateList() {
    return API.get("getQuestionTemplates");
  },

  generateQuestions(data) {
    return API.post("generateQuestions", { data });
  },

  // live paper
  generateLiveQuestions(data) {
    return API.post("generateLiveQuestions", { data });
  },
  changeLiveQuestion(q_id, sy_id) {
    return API.post("changeLiveQuestion", { q_id, sy_id });
  },
  saveLiveQuestions(data) {
    return API.post("saveLiveQuestions", { data });
  },

  // view questions

  getQuestionListApproved() {
    //  not working
    return API.get("getQuestionWithApproved");
    // return API.get('getQuestion');
  },

  getQuestionBySyllbus(sys_id) {
    return API.get(`getQuestionBySyl/${sys_id}`);
  },

  getQuestionWithSub(id) {
    // NOT WORKING
    return API.get(`getQuestionWithSub/${id}`);
  },

  // view questions

  approveQuestion(id, isApprove, approver) {
    return API.post("approveQuestion", { id, isApprove, approver });
  },

  handleAllQuestions() {
    return API.get("getQuestion");
  },

  getSubjectPrivilege(id) {
    return API.get(`/getSubjectPrivilege/${id}`);
  },
  getAllUser(page, perPage) {
    return API.get("/getAllUser");
  },
  getAllUserPaginate(page, perPage) {
    let queryString = "";
    if (page) {
      queryString += `?page=${page}`;
    }

    if (perPage) {
      queryString += `&perPage=${perPage}`;
    }
    return API.get("/getAllUserPaginate" + queryString);
  },
  reportCount() {
    return API.get("/reportCount");
  },

  // login
  register(formData) {
    return API.post("/register", { formData });
  },

  login(email, password) {
    return API.post("/login", { email, password });
  },
  importExcel(data) {
    return API.post("importExcel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  EditExelQuestion(question, w_ans, type, user) {
    return API.post("EditExelQuestion", { question, w_ans, type, user });
  },
  getExelQuestion(status) {
    return API.get(`getExelQuestion/${status}`);
  },

  // STUDY MATERIAL RELATED

  getStudyMeterials() {
    return API.get("/getStudyMeterials");
  },
  postStudyMeterials(formdata) {
    return FileAPI.post(
      "/postStudyMeterials",
      { ...formdata },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  getStudyMaterial(id) {
    return API.get(`/study_materials/${id}`);
  },

  deleteStudyMaterial(id) {
    return API.post(`/study_materials/delete`, { id });
  },

  handleChangeAnsImg(data) {
    return API.post("/handleChangeAnsImg", { data });
  },
  submittedAnswer(data) {
    return API.post("/submittedAnswer", { data });
  },
  getQuestionFirst(data) {
    return API.post("/getQuestionFirst", { data });
  },
  savePaperQuestion(questions, user_id) {
    return API.post("/savePaperQuestion", { questions, user_id });
  },
  createPaper(template, syllabus, examtype = false) {
    return API.post("createPaper", { template, syllabus, examtype });
  },
  listPaper(params) {
    return API.get("/listPaper", {
      ...params,
    });
  },
  listPaidPaper(props) {
    return API.get(
      "/papers/paid" +
        `${props && props.user_id ? `?user_id=${props.user_id}` : ""}`
    );
  },
  deletePaper(data) {
    return API.post("/deletePaper", { data });
  },

  // update paper exam id
  updatePaperExamId(paper_id, exam_name_id) {
    return API.post("/papers/update/exam-id", { paper_id, exam_name_id });
  },

  appUsers() {
    return API.get("/appUsers");
  },
  getAppUser(id) {
    return API.get(`/app-user?id=${id}`);
  },
  listLivePaperUsers(params) {
    return API.get("/papers/live/info", {
      ...params,
    });
  },
  appUsersPaginate(page, perPage, filters) {
    let queryString =
      `${filters.firstName ? `&firstName=${filters.firstName}` : ""}` +
      `${filters.lastName ? `&lastName=${filters.lastName}` : ""}` +
      `${filters.email ? `&email=${filters.email}` : ""}` +
      `${filters.id ? `&id=${filters.id}` : ""}` +
      `${filters.phone ? `&phone=${filters.phone}` : ""}` +
      `${page ? `&page=${page}` : ""}` +
      `${perPage ? `&perPage=${perPage}` : ""}`;

    return API.get("/appUsersPaginate?" + queryString);
  },

  appUsersPurchases(filters) {
    return API.get("/app-user/purchases", {
      params: {
        ...filters,
      },
    });
  },
  appUserPapers(filters) {
    return API.get("/app-user/papers", {
      params: {
        ...filters,
      },
    });
  },
  appUsersTotalStats(id) {
    return API.get(`/app-user/total-stats`, {
      params: {
        id,
      },
    });
  },
  getMedals() {
    return API.get("/getMedals");
  },
  deleteMedalMaster(data) {
    return API.post("/deleteMedalMaster", { data });
  },
  addPackages(data) {
    return API.post("/addPackages", data);
  },
  getPackage() {
    return API.get("/getPackages");
  },
  getRewards() {
    return API.get("/getRewards");
  },
  packageStatus(data) {
    return API.post("/packageStatus", { data });
  },
  getIndividualPaper(data) {
    return API.post("/getIndividualPaper", { paper_id: data });
  },
  savePassageQuestion(data) {
    return API.post("/savePassageQuestion", { data });
  },
  getQuestionPage(page, filter, isApprove) {
    // console.log(page, filter, isApprove, 'on api test');
    return API.post("getQuestionPage", { page, filter, isApprove });
  },
  multipleImportFromExel(data) {
    return API.post("/multipleImportFromExel", { data });
  },

  // get papers with paper type
  getPapers(paper_type) {
    return API.post("/app/getPapers", { paper_type });
  },

  // save special paper bundle or package
  savePaperBundle(papers, pricing) {
    return API.post("/savePaperBundle", { papers, pricing });
  },

  getBundles() {
    return API.get("/getBundles");
  },

  // QUESTION ROUTES
  getQuestionCountForSubjectAndSyllabus({ subject_id, syllabus_id }) {
    return API.get(
      `/questions/count?subject_id=${subject_id}&syllabus_id=${syllabus_id}`
    );
  },

  // APP USER SUBSCRIPTION ROUTES
  getSubscriptionsForUser(jsonBody) {
    return API.post(`subscriptions/user`, {
      ...jsonBody,
    });
  },

  getSubscriptionsForAllAppUsers(jsonBody) {
    return API.post(`subscriptions/user/all`, {
      ...jsonBody,
    });
  },

  // file
  handleChangeAnsImg(data) {
    return FileAPI.post("/handleChangeAnsImg", { data });
  },
  getAllPendingQuestions(page, filter) {
    return API.post("/getAllPendingQuestions", { page, filter });
  },
  getQuestionPaginate(page, user_id, per_page, filter, isApprove) {
    return API.post("/getQuestionPaginate", {
      page,
      user_id,
      per_page,
      filter,
      isApprove,
    });
  },

  // PUSH NOTIFICATION RELATED
  sendPushNotificationToSelectedUsers(data) {
    return API.post("/notification/push/user/selected", { ...data });
  },

  sendPushNotificationToAllUsers(data) {
    return API.post("/notification/push/user/all", { ...data });
  },

  sendPushNotificationToUsersInBatch(data) {
    return API.post("/notification/push/user/batch", { ...data });
  },

  // EXAMNAME Related

  postExamName(data) {
    return FileAPI.post("/postExamName", data);
  },

  postExamType(data) {
    return API.post("/postExamType", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getExamsByType(exam_id) {
    return API.post(`/exam/app/getExamsByType?exam_id=${exam_id}`);
  },

  //SUBJECT RELATED
  postSubject(data) {
    return API.post("/postSubject", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // MEDAL RELATED
  medalMaster(data) {
    return API.post("/medalMaster", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  fetchExams() {
    return API.get("/exam/getPapers");
  },
};
