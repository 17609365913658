import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../App.css'
import api from '../API/api';
import AuthUser from '../API/token';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Login = () => {
  const [backdroploader, setBackdropLoader] = useState(false);



  const handleClose = () => {
    setBackdropLoader(false);
};
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const { setToken } = AuthUser();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const submit = () => {
    setLoading(true);
    api.login(email, password)
      .then((res) => {
        setToken(res.data.user, res.data.access_token)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.error === "Unauthorized") {
          setError('Password or Email is wrong');
        } else {
          setError('Network Error');
        }
      })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleClose}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <div className='loginForm'>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <h3 style={{ color: '#1976d2' }}>Learn O India</h3>
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar> */}
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal" required fullWidth name="password" label="Password" type="Password"
                id="password" value={password} onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
              />
              { error && 
                <small style={{color : 'red'}}>{error}</small>
              }
              {/* <button type="button" className='bg-none show-password' onClick={handleShowPassword}>
                        <VisibilityTwoToneIcon /> show password
                      </button> */}
              {/* </div> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={submit}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default Login;