import React, { useState, useEffect } from 'react';

import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import TestPage from './examPage';

import api from '../API/api';

const QuizPage = () => {

    const [fixed, setFixed] = useState(false);

    const [showHome, setShowHome] = useState(true);
    const [showExam, setShowExam] = React.useState(false);
    const [test, setTest] = useState(false);

    const [nameData, setNameData] = useState([]);

    useEffect(() => {
        viewName();
    }, []);

    const viewName = () => {
        api.getExamName()
            .then((res) => {
                setNameData(res.data.examname);
            })
            .catch((err) => {
            })
    }

    const handleCategory = () => {
        setShowExam(true);
        setShowHome(false);
    }

    const handleClick = () => {
        setTest(true);
        setShowExam(false);
        setShowHome(false);
    }

    function setFix() {
        if (window.scrollY >= 100) {
            setFixed(true)
        }
        else {
            setFixed(false);
        }
    }

    window.addEventListener("scroll", setFix)

    const HomeComponent = () => {
        return (
            <div className="row justify-content-center">
                <div className="col-sm-2">
                    <div className="image">
                        <img src='https://www.jotform.com/uploads/LindaJohansson/form_files/ABC_Company_-_2021-12-20T184033.494-removebg-preview.61c0a44395f9c2.31058385.png' className='img-fluid' />
                    </div>
                </div>

                <div className="col-md-12">
                    <h1 className='text-center my-5 text-white'>Quiz App</h1>
                </div>

                <div className="col-sm-2 my-3">
                    <Button variant='contained' color="secondary" size="large" endIcon={<SendIcon />} onClick={handleCategory}>
                        Start Test
                    </Button>
                </div>

            </div>
        )
    }

    const ExamComponent = () => {

        return (
            <div className="row justify-content-center">
                <div className="examComponent">
                    <div className="col-md-12">
                        <div className="my-3 text-center">
                            <h2 className='text-center text-white my-3 py-1'>Select Exam Category</h2>
                            <Button variant='contained' className='m-3'>NEET</Button>
                            <Button variant='contained' color="success">APSC</Button>
                            <Button variant='contained' color="secondary" className='m-3'>JEE</Button>
                            <Button variant='contained' color="error">BANKING</Button>
                            {nameData.map((name) => {
                                <Button variant='contained' className='m-3'>{name.examName}</Button>
                            })}
                        </div>
                        <p className='text-center text-white mt-5 next' onClick={handleClick}>
                            Next &nbsp; <SendIcon />
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {['sm'].map((expand) => (
                <Navbar key={expand} expand={expand} className={fixed ? 'fixed' : 'p-0'}>
                    <Container>
                        <Navbar.Brand href="#" className='text-white text-bold'>
                            LEARNOINDIA
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    LEARNOINDIA
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-center flex-grow-1 pe-3">
                                    <Nav.Link href="/quiz">HOME</Nav.Link>
                                    <Nav.Link className='nav-link'> START TEST &nbsp;<SendIcon />
                                    </Nav.Link>
                                </Nav>

                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}

            <section className='p-5 page'>
                <div className="container">
                    {showHome ? <HomeComponent /> : null}
                    {showExam ? <ExamComponent /> : null}
                    {test ? <TestPage /> : null}
                </div>
            </section>
        </>
    )
}

export default QuizPage;