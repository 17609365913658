import { useState } from "react";

const useMUIModalState = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleOpen = (value) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  const handleSelectedValue = (value) => {
    setSelectedValue(value);
  };

  return {
    open,
    selectedValue,
    handleOpen,
    handleClose,
    handleSelectedValue,
  };
};

export default useMUIModalState;
