import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import api from "../../API/api";
import { Paper } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';


export default function ReportsTable() {

  const [syllabus_data, setSyllabus_data] = useState([]);
  const [totalSubjects, setTotalSubjects] = useState();
  const [totalSyllabus, setTotalSyllabus] = useState();

  const [group, setGroup] = useState();

  const [questions, setQuestions] = useState({
    questionsApproved: 0,
    questionsNotApproved: 0,
    total: 0,
  })

  const [target, setTarget] = useState(50);

  useEffect(() => {
    getReports();
  }, [])

  const getReports = () => {
    api.reportCount().then((res) => {
      
      setGroup(Object.groupBy(res.data.syllabus_data, ({ subName }) => subName));

      setSyllabus_data(res.data.syllabus_data)
      setTotalSubjects(res.data.subjects)
      setTotalSyllabus(res.data.syllabus)
      setQuestions({
        questionsApproved: res.data.questionsApproved,
        questionsNotApproved: res.data.questionsNotApproved,
        total: res.data.questionsApproved + res.data.questionsNotApproved
      })
    })
      .catch((err) => {
      })
  }

  const targetset = () => {
    return (
      <>
        <select name="target" onChange={(e) => setTarget(e.target.value)} value={target} className="" id="">
          <option>20</option>
          <option>50</option>
          <option>100</option>
          <option>150</option>
          <option>200</option>
          <option>300</option>
          <option>400</option>
          <option>500</option>
        </select>
      </>
    )
  }





  return (
    <>
      {/* <Paper> */}
      <div className="row">
        <div className="col-md-3 my-3">
          <Paper elevation={5}>
            <div className="m-3 p-3">
              <div className="row">
                <div className="col">
                  <div>
                    <>Total</>
                  </div>
                  <div>
                    <strong>Subjects</strong>
                  </div>
                </div>
                <div className="col">
                  <h1>{totalSubjects && totalSubjects}</h1>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <div className="col-md-3 my-3">
          <Paper elevation={5}>
            <div className="m-3 p-3">
              <div className="row">
                <div className="col">
                  <div>
                    <>Total</>
                  </div>
                  <div>
                    <strong>Syllabus</strong>
                  </div>
                </div>
                <div className="col">
                  <h1>{totalSyllabus && totalSyllabus}</h1>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <div className="col-md-3 my-3">
          <Paper elevation={5}>
            <div className="m-3 p-3">
              <div className="row">
                <div className="col">
                  <div>
                    <>Total</>
                  </div>
                  <div>
                    <strong>Questions</strong>
                  </div>
                </div>
                <div className="col d-flex">
                  <span>
                    <h1>
                      {questions.questionsApproved && questions.questionsApproved}
                    </h1>
                  </span>
                  <span className="">
                    /{questions.total && questions.total}
                  </span>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <div className="col-md-3 my-3">
          <Paper elevation={5}>
            <div className="m-3 p-3">
              <div className="row">
                <div className="col">
                  <h6>Total</h6>
                  <strong>Target</strong>
                </div>
                <div className="col d-flex">
                  <span>
                    <h1>
                      {questions?.total}
                    </h1>
                  </span>
                  <span className="">
                    /{target * totalSyllabus}
                  </span>
                </div>
              </div>
            </div>
          </Paper>
        </div>

      </div>
      {/* </Paper> */}


      <Paper elevation={5}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead sx={{ backgroundColor: 'red', color: 'white' }}>
              <TableRow>
                <TableCell className="text-white">Subject</TableCell>
                <TableCell className="text-white">Syllabus</TableCell>
                <TableCell className="text-white" sx={{ minWidth: 250 }}>Graph {targetset()} (Target : {target})</TableCell>
                <TableCell className="text-white" align="right">Approved/Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>



              {group &&

                <>
                  {
                    Object.values(group).map((gr, index) => (

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >

                        <TableCell component="th" scope="row">
                          <strong>
                          {gr[0].subName}
                          </strong>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {gr.map((row) => (
                            <div className="report-table-height">
                              {row.syllabus}
                            </div>
                          ))}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {gr.map((row) => (
                            <div className="report-table-height">
                              <div className="" style={{ width: '100%' }}>

                                <div className="relative">
                                  <div className="abc">
                                    <div className="d-flex gap-1 ">
                                      <div className="graphView" style={{ width: '50%', position: 'relative' }}>
                                        <div className={`occupy7 ${(Number(row.approved) / target) * 100 <= 30 && 'bg-danger text-white'} ${(Number(row.approved) / target) * 100 < 100 && 'bg-warning'} ${(Number(row.approved) / target) * 100 >= 100 && 'bg-success text-white'}   `} style={{ width: (Number(row.approved) / target) * 100 + '%' }}>
                                        </div>
                                        <div className="def">
                                          <div className="text-center">
                                            Approved
                                          </div>
                                        </div>
                                      </div>
                                      <div className="">
                                        {parseInt(((row.approved) / target) * 100) > 100 ? <span>Done</span> : <> {parseInt(((row.approved) / target) * 100)} % </>}
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className="d-flex gap-1 ">
                                  <div className="graphView" style={{ width: '50%', position: 'relative' }}>
                                    <div className={`occupy7 ${(Number(row.total) / target) * 100 <= 30 && 'bg-danger text-white'} ${(Number(row.total) / target) * 100 < 100 && 'bg-warning'} ${(Number(row.total) / target) * 100 >= 100 && 'bg-success text-white'}   `} style={{ width: (Number(row.total) / target) * 100 + '%' }}>
                                    </div>

                                    <div className="def">
                                      <div className="text-center">
                                        Total
                                      </div>
                                    </div>

                                  </div>

                                  <div>
                                    {parseInt(((row.total) / target) * 100) > 100 ? <span><DoneOutlineIcon fontSize="sm" color="success" /> </span> : <> {parseInt(((row.total) / target) * 100)} % </>}
                                  </div>

                                </div>
                              </div>
                            </div>
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          {gr.map((row) => (
                            <div className="report-table-height justify-content-end">
                              <strong> {row.approved}</strong>/{row.total}
                            </div>
                          ))}
                        </TableCell>



                      </TableRow>
                    ))
                  }


                </>
              }


            </TableBody>














            {/* <TableBody>
              {syllabus_data.map((row) => (

                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >


                  <TableCell component="th" scope="row">

                    {row.subName}

                  </TableCell>
                  <TableCell>{row.syllabus}</TableCell>
                  <TableCell>
                    <div style={{ width: '100%' }}>

                      <div className="relative">
                        <div className="abc">
                          <div className="d-flex gap-1 ">
                            <div className="graphView" style={{ width: '50%', position: 'relative' }}>
                              <div className={`occupy7 ${(Number(row.approved) / target) * 100 <= 30 && 'bg-danger text-white'} ${(Number(row.approved) / target) * 100 < 100 && 'bg-warning'} ${(Number(row.approved) / target) * 100 >= 100 && 'bg-success text-white'}   `} style={{ width: (Number(row.approved) / target) * 100 + '%' }}>
                              </div>
                              <div className="def">
                                <div className="text-center">
                                  Approved
                                </div>
                              </div>
                            </div>
                            <div className="">
                              {parseInt(((row.approved) / target) * 100) > 100 ? <span>Done</span> : <> {parseInt(((row.approved) / target) * 100)} % </>}
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="d-flex gap-1 ">
                        <div className="graphView" style={{ width: '50%', position: 'relative' }}>
                          <div className={`occupy7 ${(Number(row.total) / target) * 100 <= 30 && 'bg-danger text-white'} ${(Number(row.total) / target) * 100 < 100 && 'bg-warning'} ${(Number(row.total) / target) * 100 >= 100 && 'bg-success text-white'}   `} style={{ width: (Number(row.total) / target) * 100 + '%' }}>
                          </div>

                          <div className="def">
                            <div className="text-center">
                              Total
                            </div>
                          </div>

                        </div>

                        <div>
                          {parseInt(((row.total) / target) * 100) > 100 ? <span><DoneOutlineIcon fontSize="sm" color="success" /> </span> : <> {parseInt(((row.total) / target) * 100)} % </>}
                        </div>

                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="right"> <strong> {row.approved}</strong>/{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody> */}


          </Table>
        </TableContainer >
      </Paper >

    </>
  );
}